import { Dialog } from "../ui/Dialog"
import styles from './index.module.scss'
import { Button, TextField } from '@radix-ui/themes'
import { AddressItemType, } from "@/views/Balance"
import { useState } from "react"
import * as Slider from '@radix-ui/react-slider';
import toast from "react-hot-toast"
import { useRequest } from 'ahooks'
import { CreateSendParamsType, broadcastAction, createSend } from "@/request/api"
import { useAtom } from "jotai"
import { userInfoAtom } from "@/store"
import { Transaction, TransactionBuilder, networks, ECPair, payments } from "bitcoinjs-lib"
import { createAddressWithMnemonic } from "@/utils"

export const SendDialog = ({ open, onClose, sendType, addressInfo }: { addressInfo: AddressItemType, open: boolean, onClose: () => void, sendType: string }) => {
    const [feeType, setFeeType] = useState<string>('1')
    const [feeNumber, setFeeNumber] = useState<number>(0)
    const token = addressInfo.tokens.find((_token) => _token.tokenType === sendType) ?? {} as any
    const [feeRate, setFeeRate] = useState<number>(100)
    const [dest, setDest] = useState<string>('')
    const [quantity, setQuantity] = useState<number>(0)
    const [userInfo] = useAtom(userInfoAtom)
    const [transFeeRes, setTransFeeRes] = useState<any>({})

    const { run, loading: createLoading } = useRequest(async (params: CreateSendParamsType) => await createSend(params), {
        manual: true,
        debounceWait: 1000,
        onSuccess: (res: any) => {
            setTransFeeRes(res)

            const network = networks.testnet
            const { privateKey } = createAddressWithMnemonic(userInfo.mnemonic as string, addressInfo.info.index, addressInfo.info.type)

            const keyPair = ECPair.fromPrivateKey(Buffer.from(privateKey as string, 'hex'), { network: networks.testnet });
            const unsignedTxHex = res.tx_hex

            const tx = Transaction.fromHex(unsignedTxHex);

            // console.log('tx-->', tx)

            const txb = new TransactionBuilder(networks.testnet);
            // console.log('txb-->', txb)


            for (let i = 0; i < tx.ins.length; i++) {
                const input = tx.ins[i];
                const { address } = payments.p2wpkh({ pubkey: keyPair.publicKey, network });

                // 添加输入到 TransactionBuilder
                txb.addInput(input.hash, input.index);
            }

            for (const output of tx.outs) {
                txb.addOutput(output.script, output.value);
            }


            for (let i = 0; i < tx.ins.length; i++) {
                const { output } = payments.p2wpkh({ pubkey: keyPair.publicKey, network });

                const signatureHash = tx.hashForSignature(i, output!, Transaction.SIGHASH_ALL);

                const signature = keyPair.sign(signatureHash);

                txb.sign({
                    prevOutScriptType: 'p2wpkh',
                    vin: i,
                    keyPair: keyPair,
                    witnessValue: tx.outs[i].value,
                });
            }

            const signedTx = txb.build();
            const signedTxHex = signedTx.toHex();

            runBroadcastAction(signedTxHex)
        
        },
        onError: (error: any) => {
            toast.error(error)
        }
    })

    const { run: runBroadcastAction, loading } = useRequest(async (hash: string) => await broadcastAction(hash), {
        manual: true,
        onSuccess: () => {
            toast('Transaction Submitted! Please kindly wait for the block confirmation!')
            onClose?.()
        },
        onError: (e: any) => {
            toast.error(e)
        }
    })

    const handleRequest = (_quantity: number | string, _dest: string) => {
        const { pubkey } = createAddressWithMnemonic(userInfo.mnemonic as string, addressInfo.info.index, addressInfo.info.type)

        if (_dest && _quantity) {
            const params: CreateSendParamsType = {
                source: addressInfo.address as string,
                destination: _dest,
                quantity: Number(_quantity) * 100000000,
                asset: sendType === 'BRT' ? 'XCP' : sendType,
                pubkey: [pubkey],
                fee_per_kb: feeRate === 100 ? 513024 : 103424,
                segwit: addressInfo.info.type === 'segwit'
            }

            run(params)
        }
    }

    return <Dialog
        open={open}
        title={'Send Tokens'}
        onClose={() => onClose()}
        titleClass={styles.title}
        containerClass={styles.wrap}
    >
        <>
            <div className={styles.container}>
                <p className={styles.desc}>
                    Send {token.tokenType} from your address mp27scYEjtDWaQzSUuKzGf74quXHdiZaAf to the address you enter below.
                </p>
                <p className={styles.desc}>
                    You have {token.balance} {token.tokenType} available to send from this address.
                </p>
                <div className={styles.sendWrap}>
                    <span className={styles.sendWrap_label}>Send To</span>
                    <TextField.Root className={styles.input} onChange={(e) => {
                        setDest(e.target.value)
                    }} />
                </div>
                <div className={styles.sendWrap}>
                    <span className={styles.sendWrap_label}>Send Amount</span>
                    <TextField.Root className={styles.input} type='number' onChange={(e) => {
                        setQuantity(Number(e.target.value ?? '0'))
                    }} />
                </div>
                <div className={styles.sendWrap}>
                    <p className={styles.sendWrap_label}>BitCoin Fee</p>
                    <Slider.Root className={styles.sliderRoot} defaultValue={[feeRate]} max={100} step={100} onValueChange={(e: number[]) => setFeeRate(e[0])}>
                        <Slider.Track className={styles.sliderTrack}>
                            <Slider.Range className={styles.sliderRange} />
                        </Slider.Track>
                        <Slider.Thumb className={styles.sliderThumb} aria-label="Volume" />
                    </Slider.Root>
                </div>
                {
                    transFeeRes.btc_fee && <p className={styles.divisibleDesc}>
                        Transaction fee: {(transFeeRes.btc_fee ?? 0) / 100000000} BTC
                    </p>
                }
                <p className={styles.divisibleDesc}>
                    Estimated wait: between 1 ~ {feeRate === 100 ? 1000 : 9999} blocks ({feeRate === 100 ? 501 : 101} Satoshis/Byte)
                </p>
                <div className={styles.sendWrap}>
                    <span className={styles.sendWrap_label}>Bitcoin Fee</span>
                    <div className={styles.sendWrap_control}>
                        <select value={feeType} className={styles.selectWrap} onChange={(e) => setFeeType(e.target.value)}>
                            <option value={'1'}>Normal Priority</option>
                            <option value={'2'}>Low Priority(Cheaper)</option>
                            <option value={'3'}>Custom Fee</option>
                        </select>
                        {
                            feeType === '3' && <TextField.Root className={styles.input} value={feeNumber} type='number' style={{ width: 200 }} max={token.balance} onChange={(e) => setFeeNumber(Number(e.target.value))} />
                        }
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <Button onClick={() => onClose()} className={[styles.button, styles.cancelButton].join(' ')}>Cancel</Button>
                <Button
                    disabled={!dest}
                    loading={createLoading || loading}
                    className={[styles.button, styles.continueButton].join(' ')}
                    onClick={() => {
                        if (quantity > Number(token.balance)) {
                            return toast('Quantity entered exceeds your current balance.')
                        }

                        handleRequest(quantity, dest)
                    }}
                >
                    Send
                </Button>
            </div>
        </>
    </Dialog>
}
