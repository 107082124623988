import { Dialog } from "../ui/Dialog"
import styles from './index.module.scss'
import { Button } from '@radix-ui/themes'


export const CreateTokenErrorDialog = ({ open, onClose }: { address: string, open: boolean, onClose: () => void }) => {

    return <Dialog
        open={open}
        title={'Create a Token(Asset)'}
        onClose={() => onClose()}
        titleClass={styles.title}
        containerClass={styles.wrap}
    >
        <>
            <div className={styles.container}>
                <p className={styles.desc}>
                    Cannot do this action as you have insufficient <span style={{color: 'gold'}}>BTC</span> at this address. Due to Bitcoin fees, each BitRoot action requires approximately  <span style={{color: 'gold'}}>0.0005 BTC</span> to perform.
                </p>
                <p className={styles.desc}>
                    Please deposit the necessary BTC into My Address #1 and try again.
                </p>
            </div>
            <div className={styles.footer}>
                <Button
                    className={[styles.button, styles.continueButton].join(' ')}
                    onClick={() => onClose()}
                >
                    OK
                </Button>
            </div>
        </>
    </Dialog>
}