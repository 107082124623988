import { Header } from './Header'
import styles from './index.module.scss'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Side } from './Side'
import { useAtom, useAtomValue } from 'jotai'
import { AddressListAtom, AddressType, chainAddressInfoAtom, normalizeBanlanceAtom, preferencesAtom, quoteAssetsAtom, userAddressAtom, userInfoAtom } from '@/store'
import { useEffect } from 'react'
import { createAddressWithMnemonic, getLocalMnemonicString, toBase64 } from '@/utils'
import { useRequest } from 'ahooks'
import { getChainAddressInfo, getNormalizedBalances, getPreferencesInfo, isReady, storePreferences } from '@/request/api'

export const Layout = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const userAddress = useAtomValue(userAddressAtom)
  const [userInfo, setUserInfo] = useAtom(userInfoAtom)
  const [preferences, setPreferences] = useAtom(preferencesAtom)
  const [addressList, setAddressList] = useAtom(AddressListAtom)
  const [chainAddress, setChainAddressInfo] = useAtom(chainAddressInfoAtom)
  const [normalizeBanlance, setNormalizeBalance] = useAtom(normalizeBanlanceAtom)
  const [_, setQuoteAssets] = useAtom(quoteAssetsAtom)

  const { run: runGetPreferencesInfo } = useRequest(async () => await getPreferencesInfo(toBase64(`${userAddress}_testnet`)), {
    manual: true,
    onSuccess: (res) => {
      const _preferences = res?.preferences ?? {}
      const _address_aliases = _preferences.address_aliases

      if (_address_aliases && _address_aliases.length > 0) {
        setPreferences(() => res?.preferences)

        const addressList = _address_aliases.map((addressInfo: any) => addressInfo.address)

        setAddressList(() => _address_aliases)

        runGetChainAddressInfo(addressList)
        runGetNormalizedBalances(addressList)
      } else {
        const { address: address2 } = createAddressWithMnemonic(userInfo.mnemonic as string, 1)
        const address_aliases: AddressType[] = [
          { address: userInfo.address as string, label: 'My Address #1', index: 0, type: 'normal' },
          { address: address2 as string, label: 'My Address #2', index: 1, type: 'normal' },
        ]

        const mewPreferences = {
          ...preferences,
          address_aliases,
        }
        setPreferences(() => ({ ...mewPreferences }))

        runStorePreferences({
          ...mewPreferences,
        })

        setAddressList(() => address_aliases)
        runGetChainAddressInfo([userInfo.address, address2])
        runGetNormalizedBalances([userInfo.address, address2])
      }
    },
  })

  const { run: runStorePreferences } = useRequest(async (tmpPerferences) => await storePreferences(toBase64(`${userAddress}_testnet`), tmpPerferences), {
    manual: true,
    onSuccess: (res: any) => {
      runGetPreferencesInfo()
      console.log('storePreferences', res)
    },
  })

  const { run: runGetChainAddressInfo } = useRequest(async (addressList) => await getChainAddressInfo(addressList), {
    manual: true,
    onSuccess: (res: any) => setChainAddressInfo(() => res),
  })

  const { run: runGetNormalizedBalances } = useRequest(async (addressList) => await getNormalizedBalances(addressList), {
    manual: true,
    onSuccess: (res: any) => setNormalizeBalance(() => res ?? []),
  })

  const { run: readyRun } = useRequest(async () => await isReady(), {
    manual: true,
    onSuccess: (res: any) => {
      setUserInfo((prev) => ({
        ...prev,
        netWorkBlockHeight: res.block_height,
      }))
      setQuoteAssets(() => res.quote_assets ?? [])
      runGetPreferencesInfo()
      return res
    },
  })

  useEffect(() => {
    const local = getLocalMnemonicString()

    if (!userAddress && !local) {
      navigate('/')
    }

    if (local) {
      const arr = local.split(/[\s,]+/)
      if (arr.length !== 12) return

      const { address, privateKey, mnemonic: generateMnemonic, pubkey } = createAddressWithMnemonic(local)

      setUserInfo({
        address,
        privateKey,
        pubkey,
        mnemonic: generateMnemonic,
      })

      readyRun()
    }
  }, [userAddress])

  return (
    <div className={styles.layout}>
      <Header />
      <div className={styles.container}>
        {pathname !== '/' && <Side />}
        <div className={styles.container_body}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
