import * as bip39 from 'bip39'
import { payments, networks, bip32, address as BitcoinAddres } from 'bitcoinjs-lib';
import crypto from 'crypto-js'
import { Networks, PrivateKey, HDPrivateKey } from 'bitcore-lib';


export const encryptionAddress = (address?: string, start = 6, end = 4) => {
    if (!address) return ''
    return `${address.slice(0, Math.max(0, start))}...${address.slice(-end)}`
}

export const generateMnemonic = (index: 128 | 256 = 128) => {
    const mnemonic = bip39.generateMnemonic(index);

    return {
        mnemonic
    }
}

export const getWalletInfo = (mnemonic: string, index = 0,) => {
    const seed = bip39.mnemonicToSeedSync(mnemonic)
    const root = bip32.fromSeed(seed, networks.testnet);
    const keyPair = root.derivePath(`m/0'/0'/0'/0/${index}`);
    const wallet = payments.p2pkh({ pubkey: keyPair.publicKey, network: networks.testnet });

    return { wallet, privateKey: keyPair.privateKey, keyPair }
}

export const createAddressWithMnemonic = (mnemonic: string, index = 0, type = 'normal') => {
    const seed = bip39.mnemonicToSeedSync(mnemonic)

    const root = bip32.fromSeed(seed, networks.testnet);
    
    if (type === 'normal') {
        const keyPair = root.derivePath(`m/0'/0'/0'/0/${index}`);
        const privateKey = Buffer.from(keyPair.privateKey as Buffer)?.toString('hex')
        // const pubkey = keyPair.publicKey?.toString('hex')
        const wallet = payments.p2pkh({ pubkey: keyPair.publicKey, network: networks.testnet });
        const rs = new PrivateKey(privateKey, 'testnet' as any)
        const pubkey = rs.toPublicKey().toString()
        return {
            wallet,
            mnemonic,
            address: wallet.address,
            privateKey,
            pubkey,
            mnemonicArr: mnemonic.split(' '),
            key: index,
            keyPair
        }
    }

    const segwitKey = root.derivePath(`m/0'/0'/0'/0/${index}`)

    const privateKey = segwitKey.privateKey?.toString('hex')
    const pubkey = segwitKey.publicKey?.toString('hex')

    const wallet = payments.p2wpkh({ pubkey: segwitKey.publicKey, network: networks.testnet });

    const data = BitcoinAddres.fromBech32(wallet.address as string);

    return {
        wallet,
        mnemonic,
        address: wallet.address,
        pubkey,
        privateKey,
        mnemonicArr: mnemonic.split(' '),
        key: index,
        keypair: segwitKey
    }
}

export const toBase64 = (address: string) => {
    //used for storing address alias data, for instance
    return crypto.SHA256(address).toString(crypto.enc.Base64);
}


export const getNextValidWalletIndex = (arr: number[]) => {
    const sortedArr = arr.slice().sort((a, b) => a - b);

    for (let i = 0; i < sortedArr.length - 1; i++) {
        if (sortedArr[i + 1] - sortedArr[i] > 1) {
            return (sortedArr[i] ?? 0) + 1;
        }
    }

    return (sortedArr[sortedArr.length - 1] ?? 0) + 1;
}

export const getLocalMnemonicString = () => {
    const user_local = window.sessionStorage.getItem('user_local') || '{}'
    const nowTime = new Date().getTime()
    const info = JSON.parse(user_local)

    if (!info.timeStamp || nowTime > info.timeStamp) {
        clearLocalMnemonic()
        return false
    }

    return info.local_string
}

export const setLocalMnemonicString = (mnemonicStr: string) => {
    const info = {
        timeStamp: new Date().getTime() + 24 * 60 * 60 * 1000,
        local_string: mnemonicStr
    }

    window.sessionStorage.setItem('user_local', JSON.stringify(info))
}

export const clearLocalMnemonic = () => {
    window.sessionStorage.removeItem('user_local')
}