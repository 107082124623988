import { Dialog } from "../ui/Dialog"
import styles from './index.module.scss'
import { useState } from "react"
import { Button } from '@radix-ui/themes'
import Radio from "../ui/Radio"
import { AddressListAtom, AddressType, userInfoAtom } from "@/store"
import { useAtom } from "jotai"
import { createAddressWithMnemonic, getWalletInfo } from "@/utils"
// import bitcoinMessage from 'bitcoinjs-message'
import { Transaction, TransactionBuilder, networks, ECPair, payments } from "bitcoinjs-lib"


export const SignMessageDialog = ({ open, onClose, info }: { open: boolean, onClose: () => void, info: AddressType, }) => {
    const [value, setValue] = useState<string>('')
    const [encodeType, setEnCodeType] = useState<string>('0')
    const [signedMessage, setSignMessage] = useState<string>('')
    const [addressList] = useAtom(AddressListAtom)
    const [userInfo] = useAtom(userInfoAtom)

    return <Dialog
        open={open}
        title={'Sign a Message'}
        onClose={() => onClose()}
        titleClass={styles.title}
        containerClass={styles.wrap}
    >
        <>
            <div className={styles.container}>
                <p className={styles.desc}>
                    Sign a message from address mwB4d2XtUdLgKx2fWWpdSQc8uP5edqnjLY.
                </p>
                <div className={styles.signWrap}>
                    <p className={styles.signWrap_label}>Message to Sign</p>
                    <div className={styles.textareaWrap}>
                        <textarea className={styles.textarea} value={value} onChange={(e) => setValue(e.target.value)} />
                    </div>
                </div>
                <p className={styles.signDesc}>
                    Enter the message text to be signed.
                </p>
                <div className={styles.signWrap}>
                    <p className={styles.signWrap_label}>Message to Sign</p>
                    <div className={styles.radioWrap}>
                        <Radio
                            value={encodeType}
                            items={[
                                { label: 'Base 64 Encoded (Bitcoin Core Compatible)', value: '0' },
                                { label: 'Hex Encoded', value: '1' }
                            ]}
                            onChange={(e: string) => setEnCodeType(e)}
                        />
                    </div>
                </div>
                <div className={styles.signWrap}>
                    <p className={styles.signWrap_label}>Signed Message</p>
                    <div className={styles.textareaWrap}>
                        <textarea className={styles.textarea} value={signedMessage} />
                    </div>
                </div>
                <p className={styles.signDesc}>
                    The signed message appears above.
                </p>
            </div>

            <div className={styles.footer}>
                <Button onClick={() => onClose()} className={[styles.button, styles.cancelButton].join(' ')}>Close</Button>
                <Button
                    className={[styles.button, styles.continueButton].join(' ')}
                    onClick={() => {
                        const { privateKey } = createAddressWithMnemonic(userInfo.mnemonic as string, info.index, info.type)
                        const network = networks.testnet

                        const keyPair = ECPair.fromPrivateKey(Buffer.from(privateKey as string, 'hex'), { network: networks.testnet });
                        const tx = Transaction.fromHex(value);
                        const txb = new TransactionBuilder(networks.testnet);

                        for (let i = 0; i < tx.ins.length; i++) {
                            const input = tx.ins[i];
                            const { address } = payments.p2wpkh({ pubkey: keyPair.publicKey, network });

                            // 添加输入到 TransactionBuilder
                            txb.addInput(input.hash, input.index);
                        }

                        for (const output of tx.outs) {
                            txb.addOutput(output.script, output.value);
                        }

                        for (let i = 0; i < tx.ins.length; i++) {
                            const { output } = payments.p2wpkh({ pubkey: keyPair.publicKey, network });

                            const signatureHash = tx.hashForSignature(i, output!, Transaction.SIGHASH_ALL);

                            const signature = keyPair.sign(signatureHash);

                            txb.sign({
                                prevOutScriptType: 'p2wpkh',
                                vin: i,
                                keyPair: keyPair,
                                witnessValue: tx.outs[i].value,
                            });
                        }

                        const signedTx = txb.build();
                        const signedTxHex = signedTx.toHex();

                        if (encodeType === '0') {
                            const signedTxBuffer = Buffer.from(signedTxHex, 'hex');
                            const signedTxBase64 = signedTxBuffer.toString('base64');

                            setSignMessage(signedTxBase64)
                            return
                        }

                        setSignMessage(signedTxHex)

                    }}
                >
                    Continue
                </Button>
            </div>
        </>
    </Dialog>
}