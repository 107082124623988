import { Button } from '@radix-ui/themes'
import styles from './index.module.scss'
import { isReady, getPreferencesInfo, getChainAddressInfo, storePreferences, getNormalizedBalances } from '@/request/api'
import { useState } from 'react'
import { createAddressWithMnemonic, toBase64 } from '@/utils'
import menuIcon from 'assets/images/balance/menu.png'
import moreLogo from 'assets/images/balance/more.png'
import { DropdownMenu } from '@/components/ui/DropMenu'
import { SendDialog } from '@/components/send'
import { QrCodeDialog } from '@/components/Qrcode'
import { ShowPrivateKeyDialog } from '@/components/ShowPrivateKeyDialog'
import { CreateNewAddressDialog } from '@/components/CreateNewAddressDialog'
import { SignMessageDialog } from '@/components/SignMessageDialog'
import { SignTransactionDialog } from '@/components/SignTransationDialog'
import { CreateTokenDialog } from '@/components/CreateATokenDialog'
import { useRequest } from 'ahooks'
import { useAtom, useAtomValue } from 'jotai'
import { AddressListAtom, AddressType, ChainAddressInfoType, NormalizeBanlanceType, chainAddressInfoAtom, normalizeBanlanceAtom, preferencesAtom, quoteAssetsAtom, userAddressAtom, userInfoAtom } from '@/store'
import { CreateTokenErrorDialog } from '@/components/CreateErrorDialog'
import toast from 'react-hot-toast';
import { ShowMnemonicDialog } from '@/components/ShowMnemonicDialog'
import BigNumber from 'bignumber.js'


export type AddressItemType = {
    tokens: any[],
    address: string,
    label: string,
    info: AddressType
    onRemove?: () => void,
}

const AddressItem = (props: AddressItemType) => {
    const [openSned, setOpenSend] = useState<boolean>(false)
    const [sendTokenType, setSendTokenType] = useState<string>('')
    const [showQRcode, setShowQRCode] = useState<boolean>(false)
    const [showPrivateKey, setShowPrivateKey] = useState<boolean>(false)
    const [showSignMessage, setShowSignMessage] = useState<boolean>(false)
    const [showSignTransaction, setShowSignTransaction] = useState<boolean>(false)
    const [showCreateToken, setShowCreateToken] = useState<boolean>(false)
    const [showCreateError, setShowCreateError] = useState<boolean>(false)
    const [showMnemonic, setShowMnemonic] = useState<boolean>(false)
    const [normalizeBanlance, setNormalizeBalance] = useAtom(normalizeBanlanceAtom)
    const currentAddressNormalBanlance = normalizeBanlance.filter((item) => item.address === props.address) ?? []

    const items = [
        {
            label: 'Show QR Code',
            onClick: () => setShowQRCode(true)
        },
        { label: 'Show Private Key', onClick: () => setShowPrivateKey(true) },
        { label: 'Show Mnemonic', onClick: () => setShowMnemonic(true) },
        { label: 'Sign Message', onClick: () => setShowSignMessage(true) },
        { label: 'Sign Transaction', onClick: () => setShowSignTransaction(true) },
        {
            label: 'Create a Token', onClick: () => {
                const btcToken = props.tokens.find((token) => token.tokenType === 'BTC')

                if (Number(btcToken.balance ?? '0') <= 0.0005) {
                    setShowCreateError(true)
                    return
                }

                setShowCreateToken(true)
            }
        },
        { label: 'Remove Address', onClick: () => props.onRemove?.() }
    ]

    return (
        <div className={styles.address}>
            <div className={styles.address_subject}>
                <div className={styles.labelInfo}>
                    <span> {props.label}</span>
                    {props?.info?.type !== 'normal' && <span className={styles.addressType}>{props.info.type}</span>}
                </div>
                <div className={styles.actions}>
                    <p>{props.address}</p>
                    <div className={styles.dropMenu}>
                        <img src={menuIcon} alt='' />
                        <DropdownMenu
                            tirggerEl={<div className={styles.dropActions}>Address Actions</div>}
                            items={items}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.tokenWrap}>
                {
                    props.tokens.map((token: any) => (
                        <div key={token.tokenType} className={styles.tokenItem}>
                            <div className={styles.tokenSubject}>
                                <span>{token.tokenType}</span>
                                <DropdownMenu
                                    tirggerEl={<img src={moreLogo} alt='more' className={styles.moreButton} />} items={[
                                        {
                                            label: 'send',
                                            onClick: () => {
                                                setSendTokenType(token.tokenType)
                                                setOpenSend(true)
                                            }
                                        }
                                    ]} />
                            </div>
                            <div className={styles.tokenBalance}>
                                <p className={styles.tokenBalance_name}>Balances</p>
                                <p className={styles.tokenBalance_value}>
                                    {token.balance}
                                </p>
                            </div>
                        </div>
                    ))
                }
            </div>
            {openSned && <SendDialog addressInfo={props} sendType={sendTokenType} open={openSned} onClose={() => setOpenSend(false)} />}
            {showQRcode && <QrCodeDialog address={props.address} open={showQRcode} onClose={() => setShowQRCode(false)} />}
            {showPrivateKey && <ShowPrivateKeyDialog info={props?.info} open={showPrivateKey} onClose={() => setShowPrivateKey(false)} />}
            {showSignMessage && <SignMessageDialog info={props?.info} open={showSignMessage} onClose={() => setShowSignMessage(false)} />}
            {showSignTransaction && <SignTransactionDialog info={props?.info} open={showSignTransaction} onClose={() => setShowSignTransaction(false)} />}
            {showCreateToken && <CreateTokenDialog currentAddressNormalBanlance={currentAddressNormalBanlance} info={props?.info} label={props.label} open={showCreateToken} onClose={() => setShowCreateToken(false)} onSuccess={() => toast('Create Successful')} />}
            {showCreateError && <CreateTokenErrorDialog address={props.address} open={showCreateError} onClose={() => setShowCreateError(false)} />}
            {showMnemonic && <ShowMnemonicDialog info={props?.info} open={showMnemonic} onClose={() => setShowMnemonic(false)} />}
        </div>
    )
}

export const Balance = () => {
    const [userInfo, setUserInfo] = useAtom(userInfoAtom)
    const [preferences, setPreferences] = useAtom(preferencesAtom)
    const [_, setQuoteAssets] = useAtom(quoteAssetsAtom)
    const [addressList, setAddressList] = useAtom(AddressListAtom)
    const [chainAddress, setChainAddressInfo] = useAtom(chainAddressInfoAtom)
    const [normalizeBanlance, setNormalizeBalance] = useAtom(normalizeBanlanceAtom)

    const userAddress = useAtomValue(userAddressAtom)

    const [createNewAddressShow, setCreateNewAddressShow] = useState<'normal' | 'segwit' | ''>('')

    useRequest(async () => await isReady(), {
        onSuccess: (res: any) => {
            setUserInfo((prev) => ({
                ...prev,
                netWorkBlockHeight: res.block_height
            }))
            setQuoteAssets(() => res.quote_assets ?? [])
            runGetPreferencesInfo()
            return res
        }
    })


    const { run: runGetPreferencesInfo } = useRequest(async () => await getPreferencesInfo(toBase64(`${userAddress}_testnet`)), {
        manual: true,
        onSuccess: (res) => {
            const _preferences = res?.preferences ?? {}
            const _address_aliases = _preferences.address_aliases

            if (_address_aliases && _address_aliases.length > 0) {
                setPreferences(() => res?.preferences)

                const addressList = _address_aliases.map((addressInfo: any) => addressInfo.address)

                setAddressList(() => _address_aliases)

                runGetChainAddressInfo(addressList)
                runGetNormalizedBalances(addressList)
            } else {
                const { address: address2 } = createAddressWithMnemonic(userInfo.mnemonic as string, 1)
                const address_aliases: AddressType[] = [
                    { address: userInfo.address as string, label: 'My Address #1', index: 0, type: 'normal' },
                    { address: address2 as string, label: 'My Address #2', index: 1, type: 'normal' },
                ]

                const mewPreferences = {
                    ...preferences,
                    address_aliases
                }
                setPreferences(() => ({ ...mewPreferences }))

                runStorePreferences({
                    ...mewPreferences,
                })

                setAddressList(() => address_aliases)
                runGetChainAddressInfo([userInfo.address, address2])
                runGetNormalizedBalances([userInfo.address, address2])

            }
        }
    })

    const { run: runStorePreferences } = useRequest(async (tmpPerferences) => await storePreferences(toBase64(`${userAddress}_testnet`), tmpPerferences), {
        manual: true,
        onSuccess: (res: any) => {

            runGetPreferencesInfo()
            console.log('storePreferences', res)
        }
    })

    const { run: runGetChainAddressInfo } = useRequest(async (addressList) => await getChainAddressInfo(addressList), {
        manual: true,
        onSuccess: (res: any) => setChainAddressInfo(() => res)
    })

    const { run: runGetNormalizedBalances } = useRequest(async (addressList) => await getNormalizedBalances(addressList), {
        manual: true,
        onSuccess: (res: any) => setNormalizeBalance(() => res ?? [])
    })

    return (
        <div className={styles.balance}>
            <div className={styles.buttons}>
                <DropdownMenu tirggerEl={<Button
                    className={styles.button}>
                    Create New Address</Button>}
                    items={
                        [
                            {
                                label: <p style={{ padding: '5px 10px' }}>Create Segwit Address</p>,
                                onClick: () => setCreateNewAddressShow('segwit')
                            },
                            {
                                label: <p style={{ padding: '5px 10px' }}>Create Regular Address</p>,
                                onClick: () => setCreateNewAddressShow('normal')
                            }
                        ]
                    } />
                {/* <Button className={styles.button}>Import Funds</Button> */}
                {/* <div className={styles.helper}>
                    <img src={help} alt="help" />
                    <span>Page Help</span>
                </div> */}
            </div>
            <p className={styles.subject}>My Account Balances</p>
            <p className={styles.desc}> Using the Bitroot testnet requires consuming testnet BTC and testnet BRT. You can collect it through the faucet website.</p>
            {
                addressList.map((item: AddressType, index: number) => {
                    const obj = chainAddress.find((chainItem) => chainItem.addr === item.address) ?? {} as ChainAddressInfoType

                    const balance = `${obj?.info?.balance ?? 0}` ?? '0'

                    const btcBalance = new BigNumber(balance)
                    const btcUnconfirm = new BigNumber(obj?.info?.unconfirmedBalance ?? '0')
                    const others = normalizeBanlance.filter((_token) => _token.address === item.address) ?? [] as NormalizeBanlanceType[]

                    const tokens: any[] = [
                        {
                            tokenType: 'BTC',
                            balance: btcBalance.plus(btcUnconfirm).toString(),
                        }
                    ]

                    if (others.length) {
                        others.forEach((normalToken) => {
                            const _token = {
                                tokenType: normalToken.asset_longname ? normalToken.asset_longname : normalToken.asset,
                                balance: normalToken.normalized_quantity,
                                ...normalToken
                            }

                            tokens.push(_token)

                        })
                    } else {
                        tokens.push({
                            tokenType: 'BRT',
                            balance: 0
                        })
                    }

                    return <AddressItem
                        key={item.address}
                        address={item.address}
                        tokens={tokens}
                        info={item}
                        label={item.label as string}
                        onRemove={() => {
                            const _preferences = JSON.parse(JSON.stringify(preferences))
                            const address_aliases = _preferences.address_aliases ?? []

                            address_aliases.splice(index, 1)

                            const mewPreferences = {
                                ..._preferences,
                                address_aliases
                            }

                            setPreferences(() => ({ ...mewPreferences }))

                            runStorePreferences({
                                ...mewPreferences,
                            })

                        }}
                    />
                }
                )
            }
            {createNewAddressShow && <CreateNewAddressDialog
                addressType={createNewAddressShow}
                open={!!createNewAddressShow}
                onClose={() => setCreateNewAddressShow('')}
            />}
        </div>
    )
}
