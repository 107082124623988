import { Dialog } from "../ui/Dialog"
import styles from './index.module.scss'
import { useState } from "react"
import { Button } from '@radix-ui/themes'
import { useAtom } from "jotai"
import { AddressType, userInfoAtom } from "@/store"
import { createAddressWithMnemonic } from "@/utils"
// import bitcore from 'bitcore-lib'
import toast from "react-hot-toast"
import { useRequest } from 'ahooks'
import { broadcastAction } from "@/request/api"
import { Transaction, TransactionBuilder, networks, ECPair, payments } from "bitcoinjs-lib"


export const SignTransactionDialog = ({ open, onClose, info }: { open: boolean, onClose: () => void, info: AddressType }) => {
    const [value, setValue] = useState<string>('')
    const [signedTransaction, setSignTransaction] = useState<string>('')
    const [userInfo] = useAtom(userInfoAtom)

    const { run } = useRequest(async (hex) => await broadcastAction(hex), {
        manual: true,
        onSuccess: () => {
            toast.success('Sign And Broadcast Successful')
            onClose()
        },
        onError: (e: any) => {
            toast.error(e)
        }
    })

    const handleSign = (isBroadcast?: boolean) => {
        try {
            const { privateKey } = createAddressWithMnemonic(userInfo.mnemonic as string, info.index, info.type)
            const network = networks.testnet

            const keyPair = ECPair.fromPrivateKey(Buffer.from(privateKey as string, 'hex'), { network: networks.testnet });
            const tx = Transaction.fromHex(value);
            const txb = new TransactionBuilder(networks.testnet);

            for (let i = 0; i < tx.ins.length; i++) {
                const input = tx.ins[i];
                const { address } = payments.p2wpkh({ pubkey: keyPair.publicKey, network });

                // 添加输入到 TransactionBuilder
                txb.addInput(input.hash, input.index);
            }

            for (const output of tx.outs) {
                txb.addOutput(output.script, output.value);
            }

            for (let i = 0; i < tx.ins.length; i++) {
                const { output } = payments.p2wpkh({ pubkey: keyPair.publicKey, network });

                const signatureHash = tx.hashForSignature(i, output!, Transaction.SIGHASH_ALL);

                const signature = keyPair.sign(signatureHash);

                txb.sign({
                    prevOutScriptType: 'p2wpkh',
                    vin: i,
                    keyPair: keyPair,
                    witnessValue: tx.outs[i].value,
                });
            }

            const signedTx = txb.build();
            const signedTxHex = signedTx.toHex();

            if (isBroadcast) {
                run(signedTxHex)
            }

            return signedTxHex
        } catch (e: any) {
            toast.error(e.message)
            return false
        }
    }


    return <Dialog
        open={open}
        title={'Sign a Transaction'}
        onClose={() => onClose()}
        titleClass={styles.title}
        containerClass={styles.wrap}
    >
        <>
            <div className={styles.container}>
                <p className={styles.desc}>
                    Sign a transaction with address mwB4d2XtUdLgKx2fWWpdSQc8uP5edqnjLY.
                </p>
                <div className={styles.signWrap}>
                    <p className={styles.signWrap_label}>Unsigned hex transaction to Sign</p>
                    <div className={styles.textareaWrap}>
                        <textarea className={styles.textarea} value={value} onChange={(e) => setValue(e.target.value)} />
                    </div>
                </div>
                <p className={styles.signDesc}>
                    Enter the message text to be signed.
                </p>
                <div className={styles.signWrap}>
                    <p className={styles.signWrap_label}>Signed transaction</p>
                    <div className={styles.textareaWrap}>
                        <textarea className={styles.textarea} value={signedTransaction} />
                    </div>
                </div>
                <p className={styles.signDesc}>
                    Enter the message text to be signed.
                </p>
            </div>

            <div className={styles.footer}>
                <Button onClick={() => onClose()} className={[styles.button, styles.cancelButton].join(' ')}>Close</Button>
                <Button
                    className={[styles.button, styles.continueButton].join(' ')}
                    onClick={() => {
                        const hex = handleSign()

                        if (hex) {
                            setSignTransaction(hex)
                        }

                    }}
                >
                    Sign
                </Button>
                <Button
                    className={[styles.button, styles.continueButton].join(' ')}
                    onClick={() => {
                        const hex = handleSign(true)

                        if (hex) {
                            setSignTransaction(hex)
                        }
                    }}
                >
                    Sign and Broandcast
                </Button>
            </div>
        </>
    </Dialog>
}