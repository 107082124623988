import { useLocation } from 'react-router-dom'
import { Account } from '../Account'
import { Menus } from '../Menus'
import styles from './index.module.scss'
import logo from 'assets/images/header/logo.svg'

export const Header = () => {
    const { pathname } = useLocation()
    return (
        <div className={styles.header} style={{ borderBottom: pathname === '/' ? '1px solid transparent' : '1px solid rgba(146, 146, 146, 0.3)' }}>
            <img className={styles.logo} src={logo} alt="logo" onClick={() => window.open('https://bitroot.co/', '__blank')}/>
            <Menus />
            <Account />
        </div>
    )
}
