import { toast as hotToast } from 'react-hot-toast'

const ToastContent = ({ title, message }: { message: React.ReactNode; icon?: 'success'; title: React.ReactNode }) => {
  return (
    <div style={{ width: '100%', display: "flex" }}>
      <div style={{ flex: 1, width: 0 }}>
        <div >
          {title}
        </div>
        {message && (
          <div
            style={{ marginTop: 4, padding: '2px 0', maxHeight: 300, minHeight: '14px', overflow: 'auto'}}
          >
            {message}
          </div>
        )}
      </div>
    </div>
  )
}

export type ToastMessage = React.ReactNode
export type ToastOptions = {
  title?: React.ReactNode
  duration?: number
}

export const toast = (message: ToastMessage, { title = 'info' }: ToastOptions = {}) => {
  hotToast.dismiss()

  hotToast(<ToastContent message={message} title={title} />, {
    style: {
      width: '360px',
    },
  })
}

toast.success = (message: ToastMessage, { title = 'success' }: ToastOptions = {}) => {
  hotToast.dismiss()
  hotToast.success(<ToastContent message={message} title={title} />, {
    style: {
      width: '360px',
    },
  })
}

toast.error = (message: ToastMessage, { title = 'fail', duration = 5000 }: ToastOptions = {}) => {
  hotToast.dismiss()
  hotToast.error(<ToastContent message={message} title={title} />, {
    style: {
      width: '360px',
    },
    duration,
  })
}
