import { AddressType, userInfoAtom } from "@/store"
import { Dialog } from "../ui/Dialog"
import styles from './index.module.scss'
import { Button, TextField } from '@radix-ui/themes'
import { useEffect, useState } from "react"
import Radio from "../ui/Radio"
import { Checkbox } from "../ui/CheckBox"
import * as Slider from '@radix-ui/react-slider';
import { useRequest } from 'ahooks'
import { CreateTokenParamsType, broadcastAction, createTokenAction } from "@/request/api"
import { createAddressWithMnemonic } from "@/utils"
import { useAtom } from "jotai"
import toast from "react-hot-toast"
import { Transaction, TransactionBuilder, networks, ECPair, payments } from "bitcoinjs-lib"
import BigNumber from "bignumber.js"


export const CreateTokenDialog = ({ open, onClose, info, onSuccess, currentAddressNormalBanlance }: { currentAddressNormalBanlance: any[], open: boolean, onClose: () => void, label: string, info: AddressType, onSuccess: () => void }) => {
    const [userInfo] = useAtom(userInfoAtom)
    const [nameType, setNameType] = useState<string>('0')
    const [namePrev, setNamePrev] = useState<string>('')
    const [tokenName, setTokenName] = useState<string>('')
    const [desc, setDesc] = useState<string>('')
    const [quantity, setQuantity] = useState<number>(0)
    const [isDivisible, setIsDivisible] = useState<boolean>(true)
    const [feeRate, setFeeRate] = useState<number>(100)
    const [txInfo, setTxInfo] = useState<any>({})

    const { run, loading } = useRequest(async (params: CreateTokenParamsType) => await createTokenAction(params), {
        manual: true,
        debounceWait: 1000,
        onSuccess: (rs: any) => {
            setTxInfo(rs)

            const network = networks.testnet
            const { privateKey } = createAddressWithMnemonic(userInfo.mnemonic as string, info.index, info.type)

            const unsignedTxHex = rs.tx_hex
            const keyPair = ECPair.fromPrivateKey(Buffer.from(privateKey as string, 'hex'), { network: networks.testnet });
            const tx = Transaction.fromHex(unsignedTxHex);


            const txb = new TransactionBuilder(networks.testnet);


            for (let i = 0; i < tx.ins.length; i++) {
                const input = tx.ins[i];
                const { address } = payments.p2wpkh({ pubkey: keyPair.publicKey, network });

                // 添加输入到 TransactionBuilder
                txb.addInput(input.hash, input.index);
            }

            for (const output of tx.outs) {
                txb.addOutput(output.script, output.value);
            }


            for (let i = 0; i < tx.ins.length; i++) {
                const { output } = payments.p2wpkh({ pubkey: keyPair.publicKey, network });

                const signatureHash = tx.hashForSignature(i, output!, Transaction.SIGHASH_ALL);

                const signature = keyPair.sign(signatureHash);

                txb.sign({
                    prevOutScriptType: 'p2wpkh',
                    vin: i,
                    keyPair: keyPair,
                    witnessValue: tx.outs[i].value,
                });
            }

            const signedTx = txb.build();
            const signedTxHex = signedTx.toHex();

            runBroadcastAction(signedTxHex)
        },
        onError: (e: any) => {
            toast.error(e)
        }
    })

    const { run: runBroadcastAction, loading: broadcaseLoading } = useRequest(async (hash: string) => await broadcastAction(hash), {
        manual: true,
        onSuccess: () => {
            toast('Create Token Successful')
            onClose?.()
        }
    })

    useEffect(() => {
        if (nameType === '2') {
            const firstDigit = Math.floor(Math.random() * 9) + 1; // 确保第一位不是0
            let digits = firstDigit.toString();

            for (let i = 0; i < 18; i++) {
                digits += Math.floor(Math.random() * 10); // 生成其余的4位数字
            }

            const name = 'A' + digits;

            setTokenName(name)
        }
    }, [nameType])

    return <Dialog
        open={open}
        title={'Create (Issue) a Token'}
        onClose={() => onClose()}
        titleClass={styles.title}
        containerClass={styles.wrap}
    >
        <>
            <div className={styles.container}>
                <div className={styles.items}>
                    <p className={styles.label}>For Address</p>
                    <p className={styles.address}>{info.address}</p>
                </div>
                <div className={styles.items} style={{ alignItems: 'flex-start' }}>
                    <p className={styles.label}>Name Type</p>
                    <Radio
                        value={nameType}
                        items={[
                            { label: 'Alphabetic name with anti-spam fee (0.5 BRT)', value: '0', desc: `Must contain between 4 and 12 uppercase letters only (A-Z), and cannot start with 'A'.` },
                            { label: 'Subasset with anti-spam fee (0.25 BRT)', value: '1', desc: 'Subasset name must be < 255 characters, may contain A-Z, a-z, 0-9, or characters .-_@! and must not end with a period', disabled: !currentAddressNormalBanlance?.length },
                            { label: 'Free numeric name', value: '2', desc: `Must start with 'A', followed by a large random number.` }
                        ]}
                        onChange={(e: string) => setNameType(e)}
                    />
                </div>
                {nameType === '1' && <div className={styles.items}>
                    <p className={styles.label}>Parent Asset</p>
                    <select onChange={(e) => {
                        setNamePrev(e.target.value)
                    }
                    }>
                        {
                            currentAddressNormalBanlance.filter((asset) => !['XCP','BRT'].includes(asset.asset)).map((asset) => (<option key={asset.asset} value={asset.asset}>{asset.asset}</option>))
                        }

                    </select>
                </div>}
                <div className={styles.items}>
                    <p className={styles.label}>Token Name</p>
                    <TextField.Root defaultValue={tokenName} onChange={(e) => {
                        setTokenName(e.target.value)
                    }} placeholder='Enter your token name' className={styles.walletInput} />
                </div>
                <div className={styles.items}>
                    <p className={styles.label}>Description</p>
                    <TextField.Root defaultValue={desc} onChange={(e) => setDesc(e.target.value)} className={styles.walletInput} />
                </div>
                <div className={styles.items}>
                    <p className={styles.label}>Quantity</p>
                    <TextField.Root type={'number'} defaultValue={quantity} onChange={(e) => {
                        setQuantity(Number(e.target.value ?? '0'))
                    }} className={styles.walletInput} />
                </div>
                <div className={styles.items}>
                    <p className={styles.label}>Make divisible</p>
                    <Checkbox chekced={isDivisible} onChange={(e: boolean) => setIsDivisible(e)} />
                </div>
                <p className={styles.divisibleDesc}>Divisible tokens can be subdivided into decimal places. If you are unsure, keep this option checked.
                </p>
                <div className={styles.items}>
                    <p className={styles.label}>BitCoin Fee</p>
                    <Slider.Root className={styles.sliderRoot} defaultValue={[feeRate]} max={100} step={100} onValueChange={(e: number[]) => setFeeRate(e[0])}>
                        <Slider.Track className={styles.sliderTrack}>
                            <Slider.Range className={styles.sliderRange} />
                        </Slider.Track>
                        <Slider.Thumb className={styles.sliderThumb} aria-label="Volume" />
                    </Slider.Root>
                </div>
                <p className={styles.divisibleDesc}>
                    Estimated wait: between 1 ~ {feeRate === 100 ? 1000 : 9999} blocks ({feeRate === 100 ? 501 : 101} Satoshis/Byte)
                </p>
            </div>
            <div className={styles.footer}>
                <Button
                    className={[styles.button, styles.cancelButton].join(' ')}
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
                <Button
                    loading={loading || broadcaseLoading}
                    variant="classic"
                    disabled={new BigNumber(quantity).eq(0) || !tokenName}
                    className={[styles.button].join(' ')}
                    color={"green"}
                    onClick={() => {
                        if (nameType === '0') {
                            const regex = /^(?!A)[A-Z]{4,12}$/;
                            const rs = regex.test(tokenName)

                            if (!rs) {
                                return toast(`Must contain between 4 and 12 uppercase letters only (A-Z), and cannot start with 'A'.`)
                            }
                        }

                        if (nameType === '2') {
                            const regex = /^A\d{5,}$/

                            const rs = regex.test(tokenName)

                            if (!rs) {
                                return toast(`Must start with 'A', followed by a large random number.`)
                            }
                        }

                        let correctName = true


                        if (new BigNumber(quantity).lte(0) || !correctName) {
                            return
                        }

                        const rs = createAddressWithMnemonic(userInfo.mnemonic as string, info.index ?? 0, info.type)

                        const params: CreateTokenParamsType = {
                            asset: `${nameType === '1' ? `${namePrev}.` : ''}${tokenName}`,
                            description: desc,
                            quantity: quantity * 100000000,
                            source: info.address,
                            divisible: isDivisible,
                            pubkey: rs.pubkey as string,
                            fee_per_kb: feeRate === 100 ? 513024 : 103424
                        }

                        run(params)
                    }}
                >
                    OK
                </Button>
            </div>
        </>
    </Dialog>
}
