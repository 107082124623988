import { Dialog } from "../ui/Dialog"
import styles from './index.module.scss'
import refreshIcon from 'assets/images/global/refresh.svg'
import { generateMnemonic } from '@/utils'
import { useEffect, useState } from "react"
import { Checkbox } from "../ui/CheckBox"
import { Button } from '@radix-ui/themes'


export const CreateMnemonicDialog = ({ open, onClose, onCreate }: { open: boolean, onClose: () => void, onCreate: (mnemonic: string) => void }) => {
    const [mnemonic, setmnomonic] = useState<string>('')
    const [isAgree, setIsAgree] = useState<boolean>(false)

    useEffect(() => {
        const { mnemonic } = generateMnemonic()

        setmnomonic(mnemonic)
    }, [])

    return <Dialog
        open={open}
        title={'Get Your Passphrase'}
        onClose={() => onClose()}
        titleClass={styles.title}
        containerClass={styles.wrap}
    >
        <>
            <div className={styles.container}>
                <p className={styles.desc}>
                    We have created a passphrase for you in the box below.
                </p>
                <p className={styles.desc}>
                    This passphrase lets you access your wallet and the funds it contains.
                </p>
                <div className={styles.mnemonicContainer}>
                    <div className={styles.mnemonics}>
                        {mnemonic}
                    </div>
                    <img
                        className={styles.refreshButton}
                        src={refreshIcon}
                        alt="refresh"
                        onClick={() => {
                            const { mnemonic } = generateMnemonic()
                          
                            setmnomonic(mnemonic)
                        }}
                    />
                </div>
                <div className={styles.safeInfo}>
                    <p className={styles.safeInfo_title}>Write your passphrase down and keep it safe.</p>
                    <p className={styles.safeInfo_li}>
                        -  If you lose this passphrase, you will lose access to your wallet forever.
                    </p>
                    <p className={styles.safeInfo_li}>
                        -  If someone gets your passphrase, they gain access to your wallet.
                    </p>
                    <p className={styles.safeInfo_li}>
                        -  We do not store your passphrase and cannot recover it if lost.
                    </p>
                </div>
                <div className={styles.server}>
                    <Checkbox chekced={isAgree} onChange={(e: boolean) => setIsAgree(e)} />
                    <p className={styles.server_text}>I have written down or otherwise securely stored my passphrase.</p>
                </div>
            </div>
            <div className={styles.footer}>
                <Button onClick={() => onClose()} className={[styles.button, styles.cancelButton].join(' ')}>Cancel</Button>
                <Button
                    className={[styles.button, styles.continueButton].join(' ')}
                    onClick={() => {
                        if (!isAgree) return

                        onCreate(mnemonic)
                    }}
                >
                    Continue
                </Button>
            </div>
        </>
    </Dialog>
}