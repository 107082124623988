import { atom } from 'jotai'

type UserInfo = {
  mnemonic?: string,
  address?: string,
  privateKey?: string,
  id?: string,
  netWorkBlockHeight?: number,
  pubkey?: string
}

export const userInfoAtom = atom<UserInfo>({})

export const userAddressAtom = atom((get) => get(userInfoAtom).address)

export const quoteAssetsAtom = atom<string[]>([])

const DEFAULT_NUM_ADDRESSES = 1

export const preferencesAtom = atom<any>({
  'num_addresses_used': DEFAULT_NUM_ADDRESSES,
  'num_segwit_addresses_used': DEFAULT_NUM_ADDRESSES,
  'address_aliases': [],
  'selected_theme': 'ultraLight',
  'selected_lang': 'en-us',
  'watch_only_addresses': [],
  'armory_offline_addresses': [],
  'multisig_addresses': [],
  'has_accepted_license': false
})

export type AssetsType = {
  address: string,
  assets: string,
  locked: boolean,
  supply: number,
  issuer: string,
  divisible: boolean
  asset_longname: any,
  owner: string
}

export type ChainItemType = {
  "addrStr": string,
  "balance": number,
  "balanceSat": string,
  "unconfirmedBalance": string,
  "unconfirmedBalanceSat": string
}

export type utxoType = {
  "address": string,
  "txid": string,
  "vout": number,
  "ts": number,
  "amount": string,
  "confirmations": number,
  "confirmationsFromCache": boolean
}

export type ChainAddressInfoType = {
  addr: string,
  block_height: number,
  info: ChainItemType,
  last_txns: any[],
  uxtos: utxoType[]
}

export const chainAddressInfoAtom = atom<ChainAddressInfoType[]>([])

export type AddressType = {
  label?: string,
  type?: 'normal' | 'segwit',
  address: string,
  index: number
}

export const AddressListAtom = atom<AddressType[]>([])

export type  AddressInfo  = Record<string, AddressType>

export const AddressInfosMapAtom = atom(get => {
  return get(AddressListAtom).reduce((acc, item) => {
    return {
      ...acc,
      [item.address]: item
    }
  }, {} as AddressInfo)
})

export type NormalizeBanlanceType = {
  "address": string,
  "asset": string,
  "quantity": number,
  "block_index": number,
  "tx_index": number,
  "rowid": number,
  "divisible": number,
  "normalized_quantity": number,
  "owner": boolean,
  "asset_longname": any
}

export const normalizeBanlanceAtom = atom<NormalizeBanlanceType[]>([])
