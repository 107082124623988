import styles from './index.module.scss'
import { Box, Button, TextField } from '@radix-ui/themes'
import toRight from 'assets/images/home/toRight.svg'
import water from 'assets/images/home/water.png'
import { useMemo, useState } from 'react'
import { CreateMnemonicDialog } from '@/components/CreateMnemonicDialog'
import { useNavigate } from 'react-router-dom'
import { createAddressWithMnemonic, setLocalMnemonicString } from '@/utils'
import { useAtom } from 'jotai'
import { userInfoAtom } from '@/store'

export const Home = () => {
    const [openCreateMnemonic, setOpenCreateMnemonic] = useState<boolean>(false)
    const navigate = useNavigate()
    const [myMnemonic, setMnemonic] = useState<string>('')
    const [userInfo, setUserInfo] = useAtom(userInfoAtom)

    const couldOpenWallet = useMemo(() => myMnemonic.split(/[\s,]+/).length !== 12, [myMnemonic])
    return (
        <div className={styles.home} style={{ backgroundImage: `url(${water})` }}>
            <p className={styles.title}>Welcome to Bitroot</p>
            <p className={styles.subTitle}>Powered by Bitroot</p>
            <Button className={styles.createButton} onClick={() => setOpenCreateMnemonic(true)}>Create New Wallet</Button>
            <div className={styles.container_wrap}>
                <div className={styles.container_body}>
                    <div className={styles.buttonWrap}>
                        <TextField.Root type='password' defaultValue={myMnemonic} onChange={(e) => setMnemonic(e.target.value)} className={styles.walletInput} />
                        <Button
                            className={styles.openWallet}
                            disabled={couldOpenWallet}
                            onClick={() => {
                                const arr = myMnemonic.split(/[\s,]+/)
                                if (arr.length !== 12) return

                                const { address, privateKey, mnemonic: generateMnemonic, pubkey } = createAddressWithMnemonic(myMnemonic)

                                setUserInfo({
                                    address,
                                    privateKey,
                                    pubkey,
                                    mnemonic: generateMnemonic
                                })

                                setLocalMnemonicString(generateMnemonic)
                                setOpenCreateMnemonic(false)
                                navigate('/balance')
                            }}
                        >
                            <Box className={styles.openWalletText}>
                                Open Wallet
                            </Box>
                            <img src={toRight} className={styles.openWallet_icon} alt='toRight' />
                        </Button>
                    </div>
                    <div className={styles.openInfo}>
                        <span className={styles.openInfo_text}>Log into your wallet</span>
                        <span className={styles.openInfo_tag}>Testnet In Use</span>
                    </div>
                </div>

            </div>
            {
                openCreateMnemonic && <CreateMnemonicDialog
                    open={openCreateMnemonic}
                    onClose={() => setOpenCreateMnemonic(false)}
                    onCreate={(mnemonic: string) => {
                        const { address, privateKey, mnemonic: generateMnemonic, } = createAddressWithMnemonic(mnemonic)

                        setUserInfo({
                            address,
                            privateKey,
                            mnemonic: generateMnemonic
                        })

                        setOpenCreateMnemonic(false)
                        navigate('/balance')
                    }}
                />
            }
        </div>
    )
}