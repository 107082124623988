import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@radix-ui/themes/styles.css';
import { Layout } from './components/Layout';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './views/Home';
import { Market } from './views/Markets';
import { Balance } from './views/Balance';
import { OpenOrders } from './views/OpenOrders';
import { OrderHistory } from './views/OrderHistory';
import { History } from './views/History';
import { Theme } from '@radix-ui/themes';
import { MarketDetailPage } from './views/Markets/Detail';
import { Provider } from './components/provider';
import { Toaster } from 'react-hot-toast'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
  <Theme>
    <Provider>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='balance' element={<Balance />} />
            <Route path='market' element={<Market />} />
            <Route path='market/:pairSymbol' element={<MarketDetailPage />} />
            <Route path='openOrders' element={<OpenOrders />} />
            <Route path='orderHistory' element={<OrderHistory />} />
            <Route path='history' element={<History />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
    </Provider>
  </Theme >

<Toaster toastOptions={{
  style: {
      width: '220px',
      maxWidth: '220px',
      background: 'white',
      borderRadius: '8px',
      boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.20)',
      color: 'black',
      margin: 0,
  },
}} />
</>
);
