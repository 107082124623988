import { CardContainer } from '../card'
import { useParams } from 'react-router-dom'
import { Table } from '@/components/ui/Table'



export const getRandomAmount = () => {
  return Math.floor(Math.random() * 60000 + 40000)
}

export const SellOrdersBook = ({orders}: {orders: any[]}) => {
  let { pairSymbol = '' } = useParams()
  const [pairBaseSymbol, pairQuoteSymbol] = pairSymbol?.split('_')

  return (
    <CardContainer title={<div>Sell Orders</div>}>
       <Table
        columns={[{
          dataIndex: 'price',
          label: `Price(${pairBaseSymbol})`,
          render: (row) => <span style={{fontSize: 12, padding: '12px 20px'}}>{row.price}</span>
        }, {
          dataIndex: 'amount',
          label: `Amount(${pairBaseSymbol})`,
          render: (row) => <span style={{fontSize: 12, padding: '12px 20px'}}>{row.amount / 10e8}</span>
        }, {
          dataIndex: 'total',
          label: `Total(${pairQuoteSymbol})`,
          render: (row) => <span style={{fontSize: 12, padding: '12px 20px'}}>{row.total / 10e8}</span>
        }]}
        data={orders.filter((item, index) => index > orders?.length - 6)}
      />
    </CardContainer>
  )
}
