import { rpcRequest } from '@/request/api'
import { AddressListAtom, userInfoAtom } from '@/store'
import { createAddressWithMnemonic } from '@/utils'
import { useMutation } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'

export type CreateCancelOrderParams = {
  offer_hash: string
  source: string
}

export const useCreateCancelOrder = () => {
  const [addressList] = useAtom(AddressListAtom)
  const userinfo = useAtomValue(userInfoAtom)

  const {
    mutateAsync: createCancelMutateAsync,
    mutate: createCancelMutate,
    ...rest
  } = useMutation({
    mutationKey: [{ key: 'proxy_to_counterpartyd', methods: 'create_cancel' }],
    mutationFn: async (params: CreateCancelOrderParams) => {
      const addressInfo = addressList.find((address) => address.address === params.source)

      const { pubkey } = createAddressWithMnemonic(userinfo?.mnemonic as string, addressInfo?.index, addressInfo?.type)

      const rs = await rpcRequest('proxy_to_counterpartyd', {
        method: 'create_cancel',
        params: {
          ...params,
          encoding: 'auto',
          pubkey,
          allow_unconfirmed_inputs: true,
          fee_per_kb: 208923,
        },
      })

      const data = rs as unknown as string

      return data
    },
  })

  return { createCancelMutate, createCancelMutateAsync, ...rest }
}
