import { useState } from "react"
import { Dialog } from "../ui/Dialog"
import styles from './index.module.scss'
import hideImg from 'assets/images/balance/hide.png'
import { Button } from '@radix-ui/themes'
import { AddressType, userInfoAtom } from "@/store"
import { createAddressWithMnemonic } from "@/utils"
import { useAtom } from "jotai"


export const ShowMnemonicDialog = ({ open, onClose, info }: { info: AddressType, open: boolean, onClose: () => void }) => {
    const [isShow, setIsShow] = useState<boolean>(false)
    const [userInfo] = useAtom(userInfoAtom)
    const wallet = createAddressWithMnemonic(userInfo.mnemonic as string, info.index, info.type)

    return <Dialog
        open={open}
        title={'Mnemonic'}
        onClose={() => onClose()}
        titleClass={styles.title}
        containerClass={styles.wrap}
    >
        <>
            <div className={styles.container}>
                <p className={styles.address}>Please make sure nobody can look over your shoulder or see your screen.</p>
                <div className={styles.sendWrap}>
                    {
                        !isShow ? <div className={styles.maskWrap}>
                            <img className={styles.mask} src={hideImg} alt={''} onClick={() => setIsShow(true)} />
                            <p className={styles.maskWrap_text}>Show Mnemonic</p>
                        </div> : <p className={styles.privateKey}>{wallet?.mnemonic}</p>
                    }
                </div>
            </div>
            <div className={styles.footer}>
                <Button
                    className={[styles.button, styles.continueButton].join(' ')}
                    onClick={() => onClose()}
                >
                    OK
                </Button>
            </div>
        </>

    </Dialog>
}