
import { cn } from '@/utils/cn'
import { useParams } from 'react-router-dom'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { useAtom, useAtomValue } from 'jotai'
import { AddressListAtom, AddressType, chainAddressInfoAtom, userInfoAtom } from '@/store'
import { useMemo, useState } from 'react'
import { broadcastAction, createOrder, getNormalizedBalances } from '@/request/api'
import { useRequest } from 'ahooks'
import * as Slider from '@radix-ui/react-slider';
import styles from '../../../../../components/send/index.module.scss'
import { Transaction, TransactionBuilder, networks, ECPair, payments } from "bitcoinjs-lib"
import toast from 'react-hot-toast'
import { createAddressWithMnemonic } from '@/utils'
import bigNumber from 'bignumber.js'
import { TextField } from '@radix-ui/themes'

export const SellOrdersForm = ({ marketList }: { marketList: any[] }) => {
  let { pairSymbol = '' } = useParams()
  const [pairBaseSymbol, pairQuoteSymbol] = pairSymbol?.split('_')
  const [addressList] = useAtom(AddressListAtom)
  const [sellAddress, setSellAddress] = useState<string>('')
  const [normalizeBanlance, setNormalizeBalance] = useState<any[]>([])
  const [feeRate, setFeeRate] = useState(100)
  const [txInfo, setTxInfo] = useState<any>({})
  const [price, setPrice] = useState<string>('0')
  const [amount, setAmount] = useState<string>('0')
  const userInfo = useAtomValue(userInfoAtom)
  const [chainAddress] = useAtom(chainAddressInfoAtom)

  useRequest(async () => await getNormalizedBalances(addressList.map((item) => item.address)), {
    ready: !!addressList?.length,
    refreshDeps: [addressList?.length],
    onSuccess: (res: any) => setNormalizeBalance(() => res ?? [])
  })

  const { run } = useRequest(async (address, give_quantity, asset, get_quantity, get_asset, pubkey, fee_per_kb) => await createOrder(address, give_quantity, asset, get_quantity, get_asset, pubkey, fee_per_kb), {
    manual: true,
    debounceWait: 1000,
    onSuccess: (res) => {
      setTxInfo(res)
    },
    onError: (e: any) => {
      toast.error(e)
    }
  })

  const { run: broadcastRun } = useRequest(async (hex: string) => await broadcastAction(hex), {
    manual: true,
    onSuccess: (res) => {
      toast.success('Create order success')
    },
    onError: (e: any) => {
      toast.error(e)
    }
  })


  const balance = useMemo(() => {
    if (pairBaseSymbol === 'BTC') {
      const obj = chainAddress.find((item) => item.addr === sellAddress) ?? {} as any

      const bn = new bigNumber(obj?.info?.balance ?? 0).plus(new bigNumber(obj?.info?.unconfirmedBalance ?? 0))
      return bn
    }

    const info = normalizeBanlance?.find((item) => item.asset === pairBaseSymbol && item.address === sellAddress) ?? {}

    const bn = new bigNumber(info?.quantity ?? 0)
    return bn
  }, [sellAddress, addressList?.length])

  const marketprice = useMemo(() => {
    const _price = marketList.length > 0 ? marketList[marketList.length - 1][4] : 0

    const bn = new bigNumber(_price ?? 0)
    return bn
  }, [marketList])

  return (
    <div className={cn('flex flex-col flex-1 rounded-[15px] bg-[#101417] p-5')}>
      <div className="font-medium text-[18px] leading-[22px]">
        <div>Sell Orders</div>
      </div>

      <div className={cn('flex-1 mt-3.5 bg-black rounded-[5px]')}>
        <div className="pr-[32px] pl-[13px] pb-4 rounded-[15px] flex gap-2.5 text-[12px] leading-[12px]">
          <div className="flex flex-col">
            <div className='py-2.5 leading-[22px] border-b border-[#101417]'>Address</div>
            <div className="mt-4 h-[22px] leading-[22px]">Balance</div>
            <div className="mt-4 h-[22px] leading-[22px]">Lowest ask price</div>
            <div className="mt-4 h-[22px] leading-[22px]">Obtainable</div>
            <div className="mt-4 h-[22px] leading-[22px]">Price</div>
            <div className="mt-4 h-[22px] leading-[22px]">Amount</div>
            <div className="mt-4 h-[22px] leading-[22px]">Total</div>
            <div className="mt-4 h-[22px] leading-[22px]">Bitcoin Fee</div>
          </div>

          <div className="flex-1 flex-col">
            <div className="py-2.5 -ml-2.5 pl-2.5 border-b border-[#101417]">
              <Select onValueChange={(e) => setSellAddress(e)}>
                <SelectTrigger className="h-[22px] leading-[22px] text-[12px] py-0">
                  <SelectValue placeholder="Select a address" />
                </SelectTrigger>
                <SelectContent>
                  {
                    addressList.map((item: AddressType) => (
                      <SelectItem key={item.address} value={item.address}>{item.label}-{item.type}</SelectItem>
                    ))
                  }
                </SelectContent>
              </Select>
            </div>
            <div className="mt-4 h-[22px] leading-[22px] text-right text-[12px] ">{balance.div(10e7).toFormat()} {pairBaseSymbol}</div>
            <div className="mt-4 h-[22px] leading-[22px] text-right ">{marketprice.div(10e7).toFormat()} {pairQuoteSymbol}</div>
            <div className="mt-4 h-[22px] leading-[22px] text-right text-[12px] ">{balance.div(10e7).multipliedBy(marketprice.div(10e7)).toFormat()} {pairQuoteSymbol}</div>

            <div className="mt-4 h-[22px] leading-[22px]  flex gap-[24px]  items-center">
              <TextField.Root
                type='number'
                value={price}
                style={{ textAlign: 'right', outline: 'none', borderRadius: '4px', padding: '0 10px', flexGrow: '1' , height: '30px'}}
                onChange={(e) => {
                  if (new bigNumber(e.target.value ?? '0').lte(0)) {
                    setPrice('0')
                    return
                  }
                  setPrice(`${Number(e.target.value ?? '0')}`)
                  if (Number(e.target.value ?? '0') && Number(amount)) {
                    const addressInfo = addressList.find((item) => item.address === sellAddress)
                    const { pubkey } = createAddressWithMnemonic(userInfo.mnemonic as string, addressInfo?.index, addressInfo?.type)

                    const total = new bigNumber(e.target.value ?? '0').multipliedBy(new bigNumber(amount ?? '0').multipliedBy(10e7)).toNumber()
                    if (new bigNumber(total).gt(balance)) {
                      return
                    }
                    run(sellAddress, total, pairBaseSymbol, new bigNumber(amount ?? '0').multipliedBy(10 * 7).toNumber(), pairQuoteSymbol, pubkey, feeRate === 100 ? 513024 : 103424)
                  }
                }} />
              <div>{pairQuoteSymbol}</div>
            </div>

            <div className="mt-4 h-[22px] leading-[22px] flex gap-[24px] items-center">
              <TextField.Root
                type='number'
                style={{ textAlign: 'right', outline: 'none', borderRadius: '4px', padding: '0 10px', flexGrow: '1', height: '30px' }}
                onChange={(e) => {
                  if (new bigNumber(e.target.value ?? '0').lte(0)) {
                    setAmount('0')
                    return
                  }

                  setAmount(e.target.value ?? '0')

                  if (Number(e.target.value ?? '0') && Number(price)) {
                    const addressInfo = addressList.find((item) => item.address === sellAddress)
                    const { pubkey } = createAddressWithMnemonic(userInfo.mnemonic as string, addressInfo?.index, addressInfo?.type)

                    const total = new bigNumber(price ?? '0').multipliedBy(new bigNumber(e.target.value ?? '0').multipliedBy(10e7)).toNumber()
                    const _amount = new bigNumber(e.target.value).multipliedBy(10e7).toNumber()

                    if (new bigNumber(total).gt(balance)) {
                      return
                    }

                    run(sellAddress, _amount, pairBaseSymbol, total, pairQuoteSymbol, pubkey, feeRate === 100 ? 513024 : 103424)
                  }
                }} />
              <div>{pairBaseSymbol}</div>
            </div>

            <div className="mt-4 h-[22px] leading-[22px]  flex gap-[24px] ">
              <div className="px-2 flex-1 outline-none rounded-[4px] text-right">
                {new bigNumber(price ?? 0).multipliedBy(Number(amount ?? 0)).toFormat()}
              </div>
            </div>

            <div className="mt-4 h-[22px] leading-[22px]  flex gap-[24px] ">
              <Slider.Root className={styles.sliderRoot} defaultValue={[feeRate]} max={100} step={100} onValueChange={(e: number[]) => setFeeRate(e[0])}>
                <Slider.Track className={styles.sliderTrack}>
                  <Slider.Range className={styles.sliderRange} />
                </Slider.Track>
                <Slider.Thumb className={styles.sliderThumb} aria-label="Volume" />
              </Slider.Root>
              {txInfo?.btc_fee && <span> Transaction fee: {(txInfo.btc_fee ?? 0) / 100000000} BTC</span>}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-[24px] py-1.5 px-2.5 text-center cursor-pointer bg-[#009046] rounded-[4px]" onClick={() => {
        if(!sellAddress) {
          return false
        }
        const total = new bigNumber(price ?? '0').multipliedBy(new bigNumber(amount ?? '0').multipliedBy(10e7)).toNumber()

        if (new bigNumber(total).gt(balance)) {
          toast.error(`nsufficient ${pairBaseSymbol === 'XCP' ? 'BRT' : pairBaseSymbol} balance`)
          return
        }
        
        if (!txInfo?.tx_hex) return false

        const addressInfo = addressList.find((item) => item.address === sellAddress)

        const network = networks.testnet
        const { privateKey } = createAddressWithMnemonic(userInfo.mnemonic as string, addressInfo?.index, addressInfo?.type)

        const keyPair = ECPair.fromPrivateKey(Buffer.from(privateKey as string, 'hex'), { network: networks.testnet });
        const unsignedTxHex = txInfo.tx_hex

        const tx = Transaction.fromHex(unsignedTxHex);

        // console.log('tx-->', tx)

        const txb = new TransactionBuilder(networks.testnet);
        // console.log('txb-->', txb)


        for (let i = 0; i < tx.ins.length; i++) {
          const input = tx.ins[i];
          const { address } = payments.p2wpkh({ pubkey: keyPair.publicKey, network });

          // 添加输入到 TransactionBuilder
          txb.addInput(input.hash, input.index);
        }

        for (const output of tx.outs) {
          txb.addOutput(output.script, output.value);
        }


        for (let i = 0; i < tx.ins.length; i++) {
          const { output } = payments.p2wpkh({ pubkey: keyPair.publicKey, network });

          const signatureHash = tx.hashForSignature(i, output!, Transaction.SIGHASH_ALL);

          const signature = keyPair.sign(signatureHash);

          txb.sign({
            prevOutScriptType: 'p2wpkh',
            vin: i,
            keyPair: keyPair,
            witnessValue: tx.outs[i].value,
          });
        }

        const signedTx = txb.build();
        const signedTxHex = signedTx.toHex();

        broadcastRun(signedTxHex)

      }}>SELL({`${pairBaseSymbol}-${pairQuoteSymbol}`})</div>
      <div className="mt-3 text-[12px] font-medium leading-[1] text-center">Estimated wait: between 1 - 1000 blocks ({feeRate === 100 ? 501 : 101} Satoshis/Byte)</div>
    </div>
  )
}
