import { Table } from '@/components/ui/Table'
import styles from './index.module.scss'
import { useState } from 'react'
import { TableFooter } from '@/components/TableFooter'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { useAtom } from 'jotai'
import { AddressListAtom, AddressType } from '@/store'
import { useRequest } from 'ahooks'
import { getHistoryAction } from '@/request/api'
import dayjs from 'dayjs'

export const History = () => {
  const [addressList] = useAtom(AddressListAtom)
  const [address, setAddress] = useState<AddressType | null>(addressList[0])
  const [list, setList] = useState<any[]>([])
  //  @ts-ignore
  useRequest(async () => await getHistoryAction(address?.address), {
    ready: true,
    refreshDeps: [address?.address],
    onSuccess: (res: any[]) => {
      setList(() => res)
    },
  })

  return (
    <div className={styles.wrap}>
      <div className={styles.titles}>
        <p>Account History</p>
      </div>
      <div className={styles.body}>
        <div className={styles.table_subject}>
          <p className={styles.table_subject_label}>Show for Address</p>
          <Select
            value={address?.address}
            onValueChange={(e) => {
              const obj = addressList.find((item) => item.address === e)
              if (obj) {
                setAddress(() => obj)
              }
            }}
          >
            <SelectTrigger className="h-[42px] leading-[22px] text-[12px] py-0">
              <SelectValue placeholder="Select a address" />
            </SelectTrigger>
            <SelectContent>
              {addressList?.map((item,i) => (
                <SelectItem key={item.address + i} value={item.address}><div>{item.address}</div></SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className={styles.accountWrap}>
          <p className={styles.accountItem}>
            {address?.label} {address?.address}
          </p>
        </div>
        <div className={styles.tableWrap}>
          <Table
            columns={[
              { dataIndex: 'tx', label: 'Tx' },
              { dataIndex: 'block', label: 'Block' },
              { dataIndex: 'block_time', label: 'Block Time' },
              { dataIndex: 'source', label: 'Source' },
              { dataIndex: 'type', label: 'Type' },
              // { dataIndex: 'descript', label: 'Description' }
            ]}
            data={list.map((item) => {
              const itemInfo = addressList.find((addressInfo) => addressInfo.address === item.address) ?? ({} as AddressType)

              const tx = item['tx_hash'] || item['order_hash'] || item['id'] || ''
              let shortHash = ''

              if (tx) {
                shortHash = tx.substr(tx.length - 5)
                if (tx.length == 128) {
                  shortHash += '...' + tx?.substr(64, 5)
                }
                // else if (tx.length == 129) {
                //   shortHash += '...' + tx?.substr(65, 5)
                // }
              }

              return {
                id: item.tx_index,
                source: itemInfo.label ?? '',
                block: item.block_index,
                block_time: dayjs(item._block_time).format(),
                type: item['_category'],
                descript: '',
                tx: shortHash,
              }
            })}
            noData={<TableFooter />}
          />
        </div>
      </div>
    </div>
  )
}
