import { Table } from '@/components/ui/Table'
import styles from './index.module.scss'
import { TextField } from '@radix-ui/themes'
import { useMemo, useState } from 'react'
import { TableFooter } from '@/components/TableFooter'
import { getOrdersApi, OpenOrderRecord } from '@/request/counterparty'
import { useQuery } from '@tanstack/react-query'
import { AddressInfosMapAtom, AddressListAtom } from '@/store'
import { useAtomValue } from 'jotai'
import { formatDecimals } from '@/utils/format-decimals'
import { formatUnit } from '@/utils/format'

export type FormatedOpenOrder = OpenOrderRecord & {
  address_label?: string

  give_remaining_str: string
  give_quantity_str: string
  give_asset_display: string

  get_asset_display: string
  get_quantity_str: string
  get_remaining_str: string
}

export const useOpenOrdersFormatedData = (filterText: string) => {
  const addressInfoList = useAtomValue(AddressListAtom)
  const AddressInfosMap = useAtomValue(AddressInfosMapAtom)

  const addressList = addressInfoList.map((info) => info.address)

  const { data = [], ...rest } = useQuery({
    enabled: addressList.length > 0,
    refetchOnMount: 'always',
    queryKey: [{ key: 'getOrdersApi', addressList }],
    queryFn: async () => {
      console.log('addressList', addressList)
      const rs = await getOrdersApi(addressList)
      console.log('rs', rs)

      return rs || []
    },
  })

  const formatedOpenOrders = useMemo(() => {
    const filteredData = data.filter((item) => {
      if (!filterText) return true
      const isReserve = [item.tx_hash, item.source].some((str) => str.includes(filterText))

      return isReserve
    })

    const formatedData = filteredData.map((item) => {
      const give_asset_display = formatUnit(item.give_asset)
      const get_asset_display = formatUnit(item.get_asset)

      const formatedItem: FormatedOpenOrder = {
        ...item,
        address_label: AddressInfosMap[item.source]?.label,
        give_asset_display: give_asset_display,
        give_quantity_str: formatDecimals(item.give_quantity, {
          unit: give_asset_display,
          decimals: 8,
          volScale: 8,
        }),
        give_remaining_str: formatDecimals(item.give_remaining, {
          unit: give_asset_display,
          decimals: 8,
          volScale: 8,
        }),

        get_asset_display,
        get_quantity_str: formatDecimals(item.get_quantity, {
          unit: get_asset_display,
          decimals: 8,
          volScale: 8,
        }),
        get_remaining_str: formatDecimals(item.get_remaining, {
          unit: get_asset_display,
          decimals: 8,
          volScale: 8,
        }),
      }

      return formatedItem as FormatedOpenOrder
    })

    return formatedData
  }, [data, filterText, AddressInfosMap])

  return { formatedOpenOrders, ...rest }
}
