import { rpcRequest } from "./api"

export type OrderMatcheData = {
  id: string
  tx_index:number
  tx_hash: string,
  tx0_index: number
  tx0_hash: string
  tx0_address: string
  tx1_index: number
  tx1_hash: string
  tx1_address: string
  forward_asset: string
  forward_quantity: number
  backward_asset: string
  backward_quantity: number
  tx0_block_index: number
  tx1_block_index: number
  block_index: number
  tx0_expiration: number
  tx1_expiration: number
  match_expire_index: number
  fee_paid: number,
  status: string
}

export const getOrderMatchesApi = async (addresses: string[]) => {
  const rs = await rpcRequest('proxy_to_counterpartyd',
    {
      "method": "get_order_matches",
      "params": {
        "filters": [
          {
            "field": "tx0_address",
            "op": "IN",
            "value": [
              ...addresses
            ]
          },
          {
            "field": "tx1_address",
            "op": "IN",
            "value": [
              ...addresses
            ]
          }
        ],
        "filterop": "OR",
        "status": [
          "pending",
          "completed",
          "expired"
        ],
        "order_by": "block_index",
        "order_dir": "DESC"
      }
    }

  )

  const data: OrderMatcheData[] = rs as any
  console.log('data',data)
  return data
}



export type OpenOrderRecord =   {
  "tx_index": number,
  "tx_hash": string,
  "block_index": number,
  "source":string,
  "give_asset": "GOOGLE",
  "give_quantity": number,
  "give_remaining": number,
  "get_asset": "XCP",
  "get_quantity": number,
  "get_remaining": number,
  "expiration": number,
  "expire_index": number,
  "fee_required": number,
  "fee_required_remaining": number,
  "fee_provided": number,
  "fee_provided_remaining": number,
  "status": "expired"
}

export const getOrdersApi = async (addresses: string[]) => {
  const rs = await rpcRequest('proxy_to_counterpartyd',
    {
      "method": "get_orders",
      "params": {
        "filters": [
          {
            "field": "source",
            "op": "IN",
            "value": [
              ...addresses
            ]
          },
          {
            "field": "give_remaining",
            "op": ">",
            "value": 0
          }
        ],
        "status": "open"
      }
    }
  )

  const data: OpenOrderRecord[] = rs as any

  return data
}
