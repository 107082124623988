import { Dialog } from "../ui/Dialog"
import styles from './index.module.scss'
import { Button } from '@radix-ui/themes'
import QRCode from "react-qr-code";


export const QrCodeDialog = ({ open, onClose, address }: { address: string, open: boolean, onClose: () => void }) => {

    return <Dialog
        open={open}
        title={'Show QR Code'}
        onClose={() => onClose()}
        titleClass={styles.title}
        containerClass={styles.wrap}
    >
        <>
            <div className={styles.container}>
                <p  className={styles.address}>{address}</p>
                <div className={styles.sendWrap}>
                    <QRCode value={address} />
                </div>
            </div>
            <div className={styles.footer}>
                <Button
                    className={[styles.button, styles.continueButton].join(' ')}
                    onClick={() => onClose()}
                >
                    OK
                </Button>
            </div>
        </>
    </Dialog>
}