import { useState } from "react"
import { Dialog } from "../ui/Dialog"
import styles from './index.module.scss'
import { Button, TextField } from '@radix-ui/themes'
import { useAtom, useAtomValue } from "jotai"
import { AddressListAtom, AddressType, preferencesAtom, userAddressAtom, userInfoAtom } from "@/store"
import { createAddressWithMnemonic, getNextValidWalletIndex, toBase64 } from "@/utils"
import { useRequest } from "ahooks"
import { storePreferences } from "@/request/api"
import toast from "react-hot-toast"

export const CreateNewAddressDialog = ({ addressType, open, onClose }: { addressType: 'segwit' | 'normal', open: boolean, onClose: () => void }) => {
    const [description, setDescription] = useState<string>('')
    const [addressList, setAddressList] = useAtom(AddressListAtom)
    const [userInfo] = useAtom(userInfoAtom)
    const userAddress = useAtomValue(userAddressAtom)
    const [newAddress, setNewAddress] = useState<AddressType | null>()
    const [preferences, setPreferences] = useAtom(preferencesAtom)
    const [tmpPreferences, setTmpPreferenes] = useState<any>()

    const { run: runStorePreferences, loading } = useRequest(async (tmpPerferences) => await storePreferences(toBase64(`${userAddress}_testnet`), tmpPerferences), {
        manual: true,
        onSuccess: () => {
            setAddressList((prev) => ([
                ...prev,
                { ...newAddress as AddressType }
            ]))

            setPreferences(() => tmpPreferences)

            toast('Create successful')
            onClose?.()
        }
    })

    return <Dialog
        open={open}
        title={`Create ${addressType} Address`}
        onClose={() => onClose()}
        titleClass={styles.title}
        containerClass={styles.wrap}
    >
        <div className={styles.container}>
            <p className={styles.address}>Enter the description you'd like to use for this new address.</p>
            <div className={styles.descWrap}>
                <p className={styles.desc}>Description</p>
                <TextField.Root className={styles.input} defaultValue={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
            <div className={styles.footer}>
                <Button onClick={() => onClose()} className={[styles.button, styles.cancelButton].join(' ')}>Cancel</Button>
                <Button
                    loading={loading}
                    className={[styles.button, styles.continueButton].join(' ')}
                    onClick={() => {
                        const walletAddressList = addressList.filter((item) => item.type === addressType) ?? []

                        const idxArray = walletAddressList.map((_wallet: AddressType) => _wallet.index)

                        const newIdx = getNextValidWalletIndex(idxArray)  

                        const rs = createAddressWithMnemonic(userInfo.mnemonic as string, newIdx, addressType)

                        const _newAddress = {
                            label: description,
                            type: addressType,
                            index: newIdx,
                            address: rs.address as string,
                        }

                        setNewAddress(() => _newAddress)

                        const address_aliases = preferences?.address_aliases ?? []
                        const _addreess_aliases = [
                            ...address_aliases,
                            _newAddress
                        ]

                        const mewPreferences = {
                            ...preferences,
                            address_aliases: _addreess_aliases
                        }

                        setTmpPreferenes(() => mewPreferences)


                        runStorePreferences(mewPreferences)
                    }}
                >
                    Create New Address
                </Button>
            </div>
        </div>
    </Dialog>
}