import { Table } from '@/components/ui/Table'
import styles from './index.module.scss'
import { TextField } from '@radix-ui/themes'
import { useMemo, useState } from 'react'
import { TableFooter } from '@/components/TableFooter'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { AddressInfosMapAtom, AddressListAtom } from '@/store'
import { getOrderMatchesApi } from '@/request/counterparty'
import { formatDecimals } from '@/utils/format-decimals'

export const OrderHistory = () => {
  const [filterText, setFilterText] = useState<string>('')
  const addressInfoList = useAtomValue(AddressListAtom)
  const AddressInfosMap = useAtomValue(AddressInfosMapAtom)

  const addressList = addressInfoList.map((info) => info.address)
  const { data = [] } = useQuery({
    refetchOnMount: 'always',
    queryKey: [{ key: 'getOrderMatchesApi', addressList }],
    queryFn: async () => {
      const rs = await getOrderMatchesApi(addressList)
      return rs || []
    },
  })

  const formatedOrderMatches = useMemo(() => {
    const filteredData = data.filter((item) => {
      if (!filterText) return true
      const isReserve = [item.tx0_hash, item.tx1_hash, item.tx0_address, item.id, item.tx1_address].some((str) => str.includes(filterText))

      return isReserve
    })

    const formatedData = filteredData.map((item) => {
      const formatedItem: Record<string, any> = {
        block_index: item.block_index,
        status: item.status,
      }

      if (AddressInfosMap[item.tx0_address]) {
        formatedItem.address_label = AddressInfosMap[item.tx0_address]?.label
        formatedItem.give_quantity = item.forward_quantity
        formatedItem.get_quantity = item.backward_quantity
        formatedItem.give_asset = formatUnit(item.forward_asset)
        formatedItem.get_asset = formatUnit(item.backward_asset)
      } else {
        formatedItem.address_label = AddressInfosMap[item.tx1_address]?.label
        formatedItem.give_quantity = item.backward_quantity
        formatedItem.get_quantity = item.forward_quantity
        formatedItem.give_asset = formatUnit(item.backward_asset)
        formatedItem.get_asset = formatUnit(item.forward_asset)
      }

      formatedItem.give_quantity_str = formatDecimals(formatedItem.give_quantity, {
        unit: formatedItem.give_asset,
        decimals: 8,
        volScale: 8,
      })

      formatedItem.get_quantity_str = formatDecimals(formatedItem.get_quantity, {
        unit: formatedItem.get_asset,
        decimals: 8,
        volScale: 8,
      })

      return formatedItem
    })

    return formatedData
  }, [data, filterText, AddressInfosMap])

  console.log('filterText==>', filterText)
  return (
    <div className={styles.wrap}>
      <div className={styles.titles}>
        <p>My Order History</p>
        {/* <div className={styles.helper}>
                    <img src={help} alt="help" />
                    <span>Page Help</span>
                </div> */}
      </div>
      <div className={styles.body}>
        <div className={styles.table_subject}>
          <span>Orders matches</span>
          <TextField.Root onChange={(e) => setFilterText(e.target.value)} placeholder="Filter" className={styles.filterInput} />
        </div>

        <div className={styles.tableWrap}>
          <Table
            columns={[
              { dataIndex: 'status', label: 'status' },
              { dataIndex: 'block_index', label: 'block' },
              { dataIndex: 'address_label', label: 'address' },
              { dataIndex: 'give_quantity_str', label: 'sell_quantity' },
              { dataIndex: 'get_quantity_str', label: 'Buy buy_quantity' },
            ]}
            data={formatedOrderMatches}
            noData={<TableFooter end={formatedOrderMatches.length} start={formatedOrderMatches.length > 0 ? 1 : 0} total={formatedOrderMatches.length} />}
          />
        </div>
      </div>
    </div>
  )
}

function formatUnit(unit: string) {
  if (unit === 'XCP') {
    return 'BRT'
  }

  return unit
}
