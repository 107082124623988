import styles from './index.module.scss'
import userIcon from 'assets/images/side/user.svg'
import userIcon_active from 'assets/images/side/user_active.svg'
import marketIcon from 'assets/images/side/market.svg'
import marketIcon_active from 'assets/images/side/market_active.svg'
import openOrdersIcon from 'assets/images/side/openOrders.svg'
import openOrdersIcon_active from 'assets/images/side/openOrders_active.svg'
import orderHistoryIcon from 'assets/images/side/orderHistory.svg'
import orderHistoryIcon_active from 'assets/images/side/orderHistory_active.svg'
import historyIcon from 'assets/images/side/history.svg'
import historyIcon_active from 'assets/images/side/history_active.svg'
import arrowRight from 'assets/images/side/arrowRight.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import logout from '@/assets/images/side/logout.png'
import { clearLocalMnemonic } from '@/utils'
import { useAtom } from 'jotai'
import { userInfoAtom } from '@/store'

type SideItemProps = {
  title: string,
  icon?: string,
  activeIcon?: string,
  path: string,
  lineType?: string
}

const SideItems: SideItemProps[] = [
  {
    title: 'My Balance',
    icon: userIcon,
    activeIcon: userIcon_active,
    path: '/balance'
  },
  {
    title: 'Markets',
    icon: marketIcon,
    activeIcon: marketIcon_active,
    path: '/market'
  }, {
    title: 'Open Orders',
    icon: openOrdersIcon,
    activeIcon: openOrdersIcon_active,
    path: '/openOrders',
  },
  {
    title: 'My Orders History',
    icon: orderHistoryIcon,
    activeIcon: orderHistoryIcon_active,
    path: '/orderHistory',
    lineType: 'dashed'
  }, {
    title: 'History',
    icon: historyIcon,
    activeIcon: historyIcon_active,
    path: '/history',
    lineType: 'solid'
  }
]

const SideItem = ({ item, isActive, onClick }: { item: SideItemProps, isActive: boolean, onClick: (path: string) => void }) => {
  return (
    <div
      className={styles.sideItem}
      onClick={() => onClick(item.path)}
    >
      <img src={isActive ? item.activeIcon : item.icon} alt={'icon'} />
      <p className={styles.sideItem_title}>{item.title}</p>
    </div>
  )
}


export const Side = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [userInfo, setUserInfo] = useAtom(userInfoAtom)

  return (
    <div className={styles.side}>
      {
        SideItems.map((item: SideItemProps) => (
          <div
            key={item.path}
            className={styles.sideItemWrap}
            style={{
              borderBottom: item.lineType ?
                `1px ${item.lineType} rgba(151, 151, 151, 1)` :
                '1px solid transparent'
            }}
          >
            <SideItem
              item={item}
              isActive={pathname.startsWith(item.path)}
              onClick={(path: string) => navigate(path)}
            />
            {pathname.startsWith(item.path) && <img src={arrowRight} alt='right' className={styles.rightIcon} />}
          </div>
        ))
      }
      <img src={logout} className={styles.logout} onClick={() => {
        clearLocalMnemonic()
        setUserInfo({})
        navigate('/')
      }} />
    </div>
  )
}
