import React from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import styles from './index.module.scss'

export const Dialog = ({ open, title, children, containerClass, titleClass }: { open: boolean, onClose: () => void, title?: string, children: React.ReactElement, containerClass?: string, titleClass?: string }) => (
    <RadixDialog.Root open={open}>
        <RadixDialog.Portal>
            <RadixDialog.Overlay className="DialogOverlay" />
            <RadixDialog.Content className={['DialogContent', styles.content, containerClass].join(' ')}>
                {title && <RadixDialog.Title className={['DialogTitle', styles.title, titleClass].join(' ')}>{title}</RadixDialog.Title>}
                {children && children}
            </RadixDialog.Content>
        </RadixDialog.Portal>
    </RadixDialog.Root>
);