import { CheckIcon } from '@radix-ui/react-icons';
import styles from './index.module.scss'

export const Checkbox = ({ chekced, onChange }: { chekced?: boolean, onChange: (checked: boolean) => void }) => (
    <div className={styles.checkboxWrap} onClick={(e) => {
        e.stopPropagation()
        onChange(!chekced)
    }}>
        {chekced && <CheckIcon color='rgba(146, 146, 146, 1)' />}
    </div>
);
