import { useParams } from 'react-router-dom'
import { CardContainer } from '../card'
import * as echarts from 'echarts';
import { useEffect, useRef } from 'react';
import { useRequest } from 'ahooks';
import { getMarketPriceHistory } from '@/request/api';
import dayjs from 'dayjs';

// const TIME_OPTIONS = [
//   {
//     value: '1M',
//     label: '1M',
//   },
//   {
//     value: '3M',
//     label: '3M',
//   },
//   {
//     value: '6M',
//     label: '6M',
//   },
//   {
//     value: 'YTD',
//     label: 'YTD',
//   },
//   {
//     value: '1Y',
//     label: '1Y',
//   },
//   {
//     value: 'ALL',
//     label: 'ALL',
//   },
// ]

const data = [900, 345, 393, -108, -154, 135, 178, 286, -119, -361, -203];
var help = [];
var positive = [];
var negative = [];
for (var i = 0, sum = 0; i < data.length; ++i) {
  if (data[i] >= 0) {
    positive.push(data[i]);
    negative.push('-');
  } else {
    positive.push('-');
    negative.push(-data[i]);
  }

  if (i === 0) {
    help.push(0);
  } else {
    sum += data[i - 1];
    if (data[i] < 0) {
      help.push(sum + data[i]);
    } else {
      help.push(sum);
    }
  }
}

export const PriceHistory = () => {
  let { pairSymbol = '' } = useParams()
  const [pairBaseSymbol, pairQuoteSymbol] = pairSymbol?.split('_')
  const chartRef = useRef<any>(null)
  const domRef = useRef<any>(null)

  useRequest(async () => await getMarketPriceHistory(pairBaseSymbol, pairQuoteSymbol), {
    ready: !!pairBaseSymbol && !!pairQuoteSymbol,
    refreshDeps: [pairBaseSymbol, pairQuoteSymbol],
    onSuccess: (res: any) => {
      const list = res ?? []
      const date = !list?.length ? [dayjs().format('MM/DD')] : list.map((item: any) => dayjs(item[0] ?? 0).format('MM/DD'))
      const value = !list?.length ? [0] : list.map((item: any) => new Date(item[4] ?? 0))

      const options = {
        xAxis: {
          type: 'category',
          data: [...date],

        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          }
        },
        series: [
          {
            data: [...value],
            type: 'line',
          }
        ]
      }

      if (domRef.current) {
        if (!chartRef.current) {
          chartRef.current = echarts.init(domRef.current);
        }

        chartRef.current?.setOption(options);
      }

    }
  })

  return (
    <div>
      <CardContainer
        className="min-h-[352px] mt-5"
        title={
          <div className="flex justify-between items-center">
            <div className="font-medium text-[18px] leading-[22px] ">Price History for {`${pairBaseSymbol}/${pairQuoteSymbol}`}</div>

            {/* <div className="flex gap-[32px] px-[25px]">
              {TIME_OPTIONS.map((option) => {
                return (
                  <div key={option.value} className=" cursor-pointer text-[#929292] font-medium leading-[17px] text-[12px]">
                    {option.label}
                  </div>
                )
              })}
            </div> */}
          </div>
        }
      >
        <div className="flex-1 bg-black rounded-[5px] bg-red" style={{ height: '270px', width: '100%' }} ref={domRef}>
        </div>
      </CardContainer>
    </div>
  )
}
