// import { encryptionAddress } from '@/utils'
import { useAtom, useAtomValue } from 'jotai'
import styles from './index.module.scss'
import avatar from 'assets/images/header/avatar.svg'
import { userInfoAtom } from '@/store'
import { createAddressWithMnemonic, encryptionAddress } from '@/utils'
import { useState } from 'react'
import { CreateMnemonicDialog } from '@/components/CreateMnemonicDialog'
import { useNavigate } from 'react-router-dom'

const User = ({ createAccount }: { createAccount: () => void }) => {
    const [userInfo] = useAtom(userInfoAtom)

    if (!userInfo.address) {
        return <div className={styles.user} onClick={() => createAccount()}>
            Create Account
        </div>
    }

    return <div className={styles.user}>
        {userInfo.address ? encryptionAddress(userInfo.address.toUpperCase()) : 'Create Account'}
    </div>
}

export const Account = () => {
    const navigate = useNavigate()
    const [userInfo, setUserInfo] = useAtom(userInfoAtom)
    const [openCreateMnemonic, setOpenCreateMnemonic] = useState<boolean>(false)
    return (
        <div className={styles.account}>
            <img src={avatar} alt='avatar' className={styles.avatar} />
            <User createAccount={() => {
                setOpenCreateMnemonic(true)
            }} />
            {
                openCreateMnemonic && <CreateMnemonicDialog
                    open={openCreateMnemonic}
                    onClose={() => setOpenCreateMnemonic(false)}
                    onCreate={(mnemonic: string) => {
                        const { address, privateKey, mnemonic: generateMnemonic, } = createAddressWithMnemonic(mnemonic)

                        setUserInfo({
                            address,
                            privateKey,
                            mnemonic: generateMnemonic
                        })

                        setOpenCreateMnemonic(false)
                        navigate('/balance')
                    }}
                />
            }
        </div>
    )
}