import { Table } from '@/components/ui/Table'
import styles from './index.module.scss'
import { TextField } from '@radix-ui/themes'
import { useMemo, useState } from 'react'
import { TableFooter } from '@/components/TableFooter'
import { getOrdersApi } from '@/request/counterparty'
import { useQuery } from '@tanstack/react-query'
import { AddressInfosMapAtom, AddressListAtom, userInfoAtom } from '@/store'
import { useAtomValue } from 'jotai'
import { FormatedOpenOrder, useOpenOrdersFormatedData } from './_hooks/useOpenOrders'
import { NiceModal } from '@/components/dialog'
import { SecondaryConfirmationDialog } from '@/components/dialog/secondary-confirmation'
import { toast } from '@/components/ui/Toast'
import { broadcastAction, isReady } from '@/request/api'
import { CreateCancelOrderParams, useCreateCancelOrder } from './_hooks/useCancelOrder'
import { Transaction, TransactionBuilder, networks, ECPair, payments } from 'bitcoinjs-lib'
import { createAddressWithMnemonic } from '@/utils'
import { useRequest } from 'ahooks'

export const OpenOrders = () => {
  const [filterText, setFilterText] = useState<string>('')

  const addressInfoList = useAtomValue(AddressListAtom)

  const userInfo = useAtomValue(userInfoAtom)

  const { formatedOpenOrders } = useOpenOrdersFormatedData(filterText)

  const { createCancelMutateAsync } = useCreateCancelOrder()

  const { run: broadcastRun } = useRequest(async (hex: string) => await broadcastAction(hex), {
    manual: true,

    onSuccess: (res) => {
      toast.success('Cancel order success')
    },
    onError: (e: any) => {
      toast.error(e)
    },
  })

  function handleCancelOrder(order: FormatedOpenOrder) {
    NiceModal.show(SecondaryConfirmationDialog, {
      title: 'Confirm cancellation order',
      message: 'Requests to cancel an order will still consume BTC (necessary to pay the Bitcoin miner fee). To avoid this, let your order expire naturally.',
      confirm: {
        cb: async () => {
          var params: CreateCancelOrderParams = {
            offer_hash: order.tx_hash,
            source: order.source,
          }

          const hash = await createCancelMutateAsync(params)

          const addressInfo = addressInfoList.find((item) => item.address === order.source)

          const network = networks.testnet
          const { privateKey } = createAddressWithMnemonic(userInfo.mnemonic as string, addressInfo?.index, addressInfo?.type)

          const keyPair = ECPair.fromPrivateKey(Buffer.from(privateKey as string, 'hex'), { network: networks.testnet })

          const tx = Transaction.fromHex(hash as any)

          // console.log('tx-->', tx)

          const txb = new TransactionBuilder(networks.testnet)
          // console.log('txb-->', txb)

          for (let i = 0; i < tx.ins.length; i++) {
            const input = tx.ins[i]
            const { address } = payments.p2wpkh({ pubkey: keyPair.publicKey, network })

            // 添加输入到 TransactionBuilder
            txb.addInput(input.hash, input.index)
          }

          for (const output of tx.outs) {
            txb.addOutput(output.script, output.value)
          }

          for (let i = 0; i < tx.ins.length; i++) {
            const { output } = payments.p2wpkh({ pubkey: keyPair.publicKey, network })

            const signatureHash = tx.hashForSignature(i, output!, Transaction.SIGHASH_ALL)

            const signature = keyPair.sign(signatureHash)

            txb.sign({
              prevOutScriptType: 'p2wpkh',
              vin: i,
              keyPair: keyPair,
              witnessValue: tx.outs[i].value,
            })
          }

          const signedTx = txb.build()
          const signedTxHex = signedTx.toHex()

          broadcastRun(signedTxHex)
        },
      },
    })
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.titles}>
        <p>Open Orders</p>
        {/* <div className={styles.helper}>
                    <img src={help} alt="help" />
                    <span>Page Help</span>
                </div> */}
      </div>
      <div className={styles.body}>
        <div className={styles.table_subject}>
          <span>Open Orders</span>
          <TextField.Root onChange={(e) => setFilterText(e.target.value)} placeholder="Filter" className={styles.filterInput} />
        </div>

        <div className={styles.tableWrap}>
          <Table
            columns={[
              { dataIndex: 'address_label', label: 'Address' },
              { dataIndex: 'give_quantity_str', label: 'Sell quantity' },
              { dataIndex: 'get_quantity_str', label: 'Buy quantity' },
              { dataIndex: 'give_remaining_str', label: 'Sell Remaining' },
              { dataIndex: 'get_remaining_str', label: 'Buy Remaining' },
              {
                dataIndex: 'block',
                label: 'Block',
                render: (order: FormatedOpenOrder) => {
                  return <> #{order.expire_index}</>
                },
              },
              {
                dataIndex: 'action',
                render: (order: FormatedOpenOrder) => {
                  return (
                    <button className="cursor-pointer" disabled={false} onClick={() => handleCancelOrder(order)}>
                      Cancel
                    </button>
                  )
                },
                label: 'Action',
              },
            ]}
            data={formatedOpenOrders}
            noData={<TableFooter />}
          />
        </div>
      </div>
    </div>
  )
}
